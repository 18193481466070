<template>
  <div>
    <div
      v-if="listing.sold === false"
      class="btn btn-primary action--button"
      :class="{ 'btn-block': full, running: loading }"
      @click="open"
    >
      Reservasi Properti
    </div>
    <div class="btn bg-grey" v-else>Properti Terjual</div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: ['full', 'listing'],
  name: 'reserve-listing',
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState({
      loggedIn: (state) => state.global.loggedIn,
    }),
  },
  methods: {
    async checkUnpaidBooking() {
      try {
        this.loading = true;
        console.log('qweqeee', this.$route.query);
        let checkBooking = await this.$store.dispatch(
          'v2/booking/isBookingAllowed',
          this.listing.uuid,
        );
        return checkBooking;
      } catch (e) {
        return false;
      } finally {
        this.loading = false;
      }
    },

    async open() {
      try {
        let allowBook = true;
        let warningBook = '';
        if (this.loggedIn) {
          const result = await this.checkUnpaidBooking();
          allowBook = result.is_allowed;
          warningBook = result.warning_message;
          if (allowBook === true) {
            this.$router.push({
              path: '/transaction/checkout',
              query: { listing: this.listing.uuid },
            });
          } else {
            this.$modal.show('modal--check-booking', {
              message: warningBook,
            });
          }
        } else {
          this.$modal.show('modal--login');
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
